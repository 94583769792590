<template>
  <div style="height: 60rem;" class="bg-white max-h-screen w-full"></div>
</template>
<script>
export default {
  props: {
    vendors: Array,
    block: Object
  },
  mounted() {
    /* eslint-disable */
    this.map = new google.maps.Map(this.$el, {
      center: { lat: parseFloat(this.block.lat), lng: parseFloat(this.block.lng) },
      zoom: parseInt(this.block.zoom)
    });

    this.vendors.forEach(placeId => {
      const request = {
        placeId,
        fields: ["name", "formatted_address", "place_id", "geometry"]
      };

      var infowindow = new google.maps.InfoWindow();
      var service = new google.maps.places.PlacesService(this.map);

      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          var marker = new google.maps.Marker({
            map: this.map,
            position: place.geometry.location
          });
          google.maps.event.addListener(marker, "click", function() {
            infowindow.setContent(
              "<div><strong>" +
                place.name +
                "</strong><br>" +
                place.formatted_address +
                "</div>"
            );
            infowindow.open(this.map, this);
          });
        }
      });
    });
  }
};
</script>
