import Vue from "vue";
import "./components";
import "./app.css";
import jump from "jump.js";

import "lazysizes/plugins/object-fit/ls.object-fit";
import "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data() {
    return {
      nav: false
    };
  },
  methods: {
    jump(id) {
      jump(id);
      this.nav = false;
    },
    printPlan(url) {
      const printWindow = window.open(url, "Print");
      printWindow.addEventListener(
        "load",
        () => {
          printWindow.print();
        },
        true
      );
    }
  }
});
