<template>
  <div>
    <div class="py-12 px-4 bg-lightbeige overflow-hidden">
      <div
        class="flex ts-250 -mx-24 lg:mx-0"
        :style="{
          transform: `translateX(${-((active - half) * (75 / data.length))}%)`
        }"
      >
        <div
          :class="
            `w-1/${data.length} ${
              index === active ? 'scale pointer-events-none' : ''
            }`
          "
          style="padding: 0 3vw;"
          class="ts-250 origin-center cursor-pointer"
          v-for="(slide, index) in data"
          :key="index"
          @click="active = index"
        >
          <img :src="slide.image" />
        </div>
      </div>
    </div>
    <div class="max-w-menu mx-auto flex flex-wrap items-center">
      <div
        class="w-full lg:w-1/2 lg:order-2 px-4 relative"
        style="min-height: 30rem;"
      >
        <template v-for="(slide, index) in data">
          <transition name="fade" :key="index">
            <div v-show="index === active">
              <div
                class="text-2xl md:text-4xl display font-normal leading-none mb-2"
                style="margin-top: -0.5em;"
              >
                {{ slide.headline }}
              </div>
              <div>{{ slide.text }}</div>
            </div>
          </transition>
        </template>
      </div>
      <div
        class="w-full lg:w-1/2 lg:order-1 px-4 py-2 bg-blue text-green text-center lg:-mt-6 self-start relative"
      >
        <div
          class="h-05 bg-brown max-w-sm absolute inset-x-0 top-0 mx-auto"
        ></div>
        <div class="display text-2xl font-normal mb-05">
          Nachhaltig & Regional
        </div>
        <a :href="link" class="text-xl uppercase block">Mehr Erfahren</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Array,
    link: String
  },
  data() {
    const half = Math.ceil(this.data.length / 2) - 1;
    const active = parseInt(half);
    return {
      half,
      active
    };
  }
};
</script>
