<template>
  <video autoplay="true" muted="true">
    <source :src="url" :type="`video/${image.extension}`" />
  </video>
</template>
<script>
export default {
  props: {
    url: String,
    extension: String
  },
  mounted() {
    console.log("test");
    this.$el.play();
  }
};
</script>
